import { setIsAuthorized, setProfile } from 'app/features/loginSlice.js'
import { toast } from 'react-toastify'
import * as api from '../index.js'

export const login = (body) => async (dispatch) => {
  try {
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
    const response = await api.login(body)
    if (response.status < 300) {
      localStorage.setItem('access_token', JSON.stringify(response.data['access_token']))
      localStorage.setItem('refresh_token', JSON.stringify(response.data['refresh_token']))
      dispatch(setIsAuthorized(true))
      return { status: response.status, message: 'Login successful' }
    }
  } catch (error) {
    if (error.response) return { message: error.response.data.message, status: error.status ?? 400 }
    const e = error.toJSON()
    return { message: e.message, status: e.error_code ?? 400 }
  }
}
export const refresh = (body) => async (dispatch) => {
  try {
    const response = await api.refresh(body)
    return response
  } catch (error) {
    return error
  }
}

export const logout = () => async (dispatch) => {
  localStorage.removeItem('access_token')
  localStorage.removeItem('refresh_token')
  dispatch(setIsAuthorized(false))
}

//TODO: We should use logout endpoint instead?
/* export const logout = (body) => async (dispatch) => {
  try {
    const response = await api.logout({ refresh_token: localStorage.getItem('refresh_token') })
    if (response.status <= 400) {
      return true
    }
    return false
  } catch (error) {
    return false
  }
} */

export const getProfileAction = () => async (dispatch) => {
  try {
    const response = await api.getProfile()
    if (response.status < 300) {
      dispatch(setProfile(response.data))
    }
  } catch (error) {
    let errorData
    if (error.response) {
      errorData = { data: error.response.data.message ?? error.message, status: error.status ?? 400 }
    }
    const e = error.toJSON()
    errorData = { data: e.message, status: e.error_code ?? 400 }
    toast.error(errorData.data)
  }
}
