import * as api from '../index.js'
import webSocketService from 'utils/webSocketService.js';

export const getDocuments = () => async (dispatch) => {
  try {
    const response = await api.documentList()
    return response
  } catch (error) {
    return error
  }
}

export const downloadDocument = (body) => async (dispatch) => {
  try {
    const response = await api.documentDownload(body)
    return response
  } catch (error) {
    return error
  }
}

export const uploadDocument = (body) => async (dispatch) => {
  try {
    const response = await api.documentUpload(body)
    return response
  } catch (error) {
    return error
  }
}

export const deleteDocument = (body) => async (dispatch) => {
  try {
    const response = await api.documentDelete(body)
    return response
  } catch (error) {
    return error
  }
}



export const handleFileUpload = async (fileOrUrl, source, callback) => {

  const token = JSON.parse(localStorage.getItem('access_token'));
  const wsScheme = window.location.protocol === 'https:' ? 'wss' : 'ws';
  const wsPath = `/ws/document/upload/`;
  const WS_URL = `${wsScheme}://${process.env.REACT_APP_WS_URL}${wsPath}?access_token=${token}`;
  const CHUNK_SIZE = 64 * 1024; // 64KB

  const generateUUID = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  };

  
  // Función para dividir el archivo en fragmentos
  const splitFile = (file, chunkSize) => {
    const chunks = [];
    let offset = 0;
    while (offset < file.size) {
      const slice = file.slice(offset, offset + chunkSize);
      chunks.push(slice);
      offset += chunkSize;
    }
    return chunks;
  };

   // Función para leer un fragmento como base64
   const readChunkAsBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const dataURL = reader.result;
        const base64 = dataURL.split(',')[1];
        resolve(base64);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  // Función para validar URLs
  const isValidURL = (string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  try {
    // Generar un ID único para la subida
    const localFileId = generateUUID();

    // Conectar al WebSocket
    await webSocketService.connect(WS_URL);

    // Definir los handlers
    const handleUploadStarted = (data) => {
      console.log('Subida iniciada:', data);
      if (callback) callback('upload_started', data);
    };

    const handleChunkReceived = (data) => {
      console.log('Fragmento recibido por el servidor.');
      if (callback) callback('chunk_received', data);
    };

    const handleProcessingStarted = (data) => {
      console.log('Procesamiento del documento iniciado.');
      if (callback) callback('processing_started', data);
    };

    const handleProcessingComplete = async (data) => {
      console.log('Procesamiento completado:', data.result);
      if (callback) await callback('processing_complete', data);
      cleanup();
      // reload file list
    };

    const handleProcessingError = (data) => {
      console.error('Error en el procesamiento:', data.error);
      if (callback) callback('processing_error', data);
      cleanup();
    };

    // Registrar los listeners
    webSocketService.on('upload_started', handleUploadStarted);
    webSocketService.on('chunk_received', handleChunkReceived);
    webSocketService.on('processing_started', handleProcessingStarted);
    webSocketService.on('processing_complete', handleProcessingComplete);
    webSocketService.on('processing_error', handleProcessingError);

    // Función de limpieza para remover listeners y cerrar la conexión
    const cleanup = () => {
      webSocketService.off('upload_started', handleUploadStarted);
      webSocketService.off('chunk_received', handleChunkReceived);
      webSocketService.off('processing_started', handleProcessingStarted);
      webSocketService.off('processing_complete', handleProcessingComplete);
      webSocketService.off('processing_error', handleProcessingError);
      webSocketService.disconnect();
    };

    if (source === 'FILE_UPLOAD') {
      const file = fileOrUrl; // 'file' es un objeto File

      // Enviar mensaje de inicio de subida para archivo
      const startMessage = {
        action: 'start_upload',
        file_id: localFileId,
        file_name: file.name,
        source: source,
      };
      webSocketService.sendMessage(startMessage);
      console.log('Mensaje start_upload enviado para FILE_UPLOAD.');

      // Dividir el archivo en fragmentos
      const chunks = splitFile(file, CHUNK_SIZE);
      console.log(`Archivo dividido en ${chunks.length} fragmentos.`);

      // Función para enviar fragmentos secuencialmente
      for (let i = 0; i < chunks.length; i++) {
        const chunk = chunks[i];
        const base64Data = await readChunkAsBase64(chunk);
        const chunkMessage = {
          action: 'file_chunk',
          file_id: localFileId,
          chunk_index: i,
          total_chunks: chunks.length,
          chunk: base64Data,
        };
        webSocketService.sendMessage(chunkMessage);
        console.log(`Fragmento ${i + 1}/${chunks.length} enviado.`);
      }

      // Enviar mensaje de finalización de subida
      const finishMessage = {
        action: 'finish_upload',
        file_id: localFileId,
      };
      webSocketService.sendMessage(finishMessage);
      console.log('Mensaje finish_upload enviado.');
    } else if (source === 'EURLEX_URL') {
      const url = fileOrUrl; // 'url' es una cadena de texto

      // Validar que 'url' es una cadena
      if (typeof url !== 'string' || !isValidURL(url)) {
        throw new Error('Para EURLEX_URL, el parámetro debe ser una cadena válida de URL.');
      }

      // Enviar mensaje de inicio de subida para URL
      const startMessage = {
        action: 'start_upload',
        file_id: localFileId,
        source: source,
        url: url,
      };
      webSocketService.sendMessage(startMessage);
      console.log('Mensaje start_upload enviado para EURLEX_URL.');

      // No se necesitan enviar fragmentos ni finalizar la subida
    } else {
      throw new Error(`Fuente de subida desconocida: ${source}`);
    }
  } catch (error) {
    console.error('Error durante la subida:', error);
    if (callback) callback('error', error.message);
    // Asegurar limpieza en caso de error
    webSocketService.disconnect();
  }
};