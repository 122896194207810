import { downloadPDF as downloadPDFLoop } from 'api/loop/handler'
import { downloadPDF } from 'api/Mirror/action/action'
import { downloadThreadPDF } from 'api/SmartAssistant/action/action'
import { setPdfStatus as setPdfStatusLoop } from 'app/features/loopSlice'
import { setPdfStatus as setPdfStatusMirror } from 'app/features/mirrorSlice'
import { setPdfStatus as setPdfStatusAssistant } from 'app/features/smartChatSlice'
import { Spinner } from 'components/shared/Loaders'
import { AddBookmarkModal } from 'components/shared/Modal/AddBookmarkModal'
import { ReactTooltip } from 'components/shared/Tooltip/ReactTooltip'
import { WORKSPACE_TYPE } from 'lib/constants/BookMarkConst'
import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { twJoin } from 'tailwind-merge'
import { useDownloadPDF } from '../hooks/useDownloadPDF'

const ACTIONS_BY_WORKSPACE = {
  assistant: { downloadAction: downloadThreadPDF, setPdfStatus: setPdfStatusAssistant, fileName: 'Scout' },
  loop: { downloadAction: downloadPDFLoop, setPdfStatus: setPdfStatusLoop, fileName: 'Loop' },
  mirror: { downloadAction: downloadPDF, setPdfStatus: setPdfStatusMirror, fileName: 'Mirror' }
}

const STORE_BY_WORKSPACE = {
  assistant: 'smartChat',
  loop: 'loop',
  mirror: 'mirror'
}

const getDownloadButton = (pdfStatus, iconClassName) =>
  pdfStatus === 'generating' ? (
    <Spinner className="mx-0 size-4 text-blue-700" />
  ) : (
    <img className={iconClassName} src="/icons/download.png" alt="generate-pdf" />
  )

const getWorkspace = (pathname) =>
  Object.values(WORKSPACE_TYPE)
    .find((value) => pathname.includes(value.toLowerCase()))
    ?.toLowerCase()

export const ToolbarActions = () => {
  const { pathname } = useLocation()
  const { downloadPDF } = useDownloadPDF()
  const [showBookMarkModal, setShowBookMarkModal] = useState(false)

  const workspace = getWorkspace(pathname)

  const { downloadAction, setPdfStatus, fileName, store } = useMemo(() => {
    return {
      downloadAction: ACTIONS_BY_WORKSPACE[workspace].downloadAction,
      setPdfStatus: ACTIONS_BY_WORKSPACE[workspace].setPdfStatus,
      fileName: ACTIONS_BY_WORKSPACE[workspace].fileName,
      store: STORE_BY_WORKSPACE[workspace]
    }
  }, [workspace])

  const { data, isLoading, pdfStatus, threadId } = useSelector(({ [store]: storeByWorkspace }) => storeByWorkspace)

  const id = threadId || data.id
  const disabledButton = pdfStatus === 'generating' || data.length === 0 || Object.keys(data).length === 0 || isLoading
  const iconClassName = twJoin('size-6', disabledButton && 'opacity-50')
  const tooltipMsg = (defaultMsg) => {
    if (isLoading) return 'Waiting to fetch data...'
    return data.length === 0 || Object.keys(data).length === 0 ? 'No available' : defaultMsg
  }

  return (
    <>
      <ReactTooltip tooltipContent={tooltipMsg('Generate PDF')} className="flex">
        <button onClick={() => downloadPDF({ id, setPdfStatus, downloadAction, fileName })} disabled={disabledButton}>
          {getDownloadButton(pdfStatus, iconClassName)}
        </button>
      </ReactTooltip>

      <ReactTooltip tooltipContent={tooltipMsg('Save as Bookmark')} className="flex">
        <button onClick={() => setShowBookMarkModal(true)} disabled={disabledButton}>
          <img className={iconClassName} src="/icons/bookmark.svg" alt="logo" />
        </button>
      </ReactTooltip>

      <ReactTooltip tooltipContent="Available soon" className="flex">
        <button disabled>
          <img className="size-6 opacity-50" src="/icons/folder-add.png" alt="save" />
        </button>
      </ReactTooltip>

      <AddBookmarkModal
        isVisible={showBookMarkModal}
        onCancel={() => setShowBookMarkModal(false)}
        workspace_type={workspace.toUpperCase()}
        workspace_id={id}
      />
    </>
  )
}
